.users{
    .main-header{
        .row{
            position:relative;
            z-index: 999;
        }
    }
    .page-header{
        h1{
            position: relative;
            z-index: 999;
        }
    }
    ul.tabs{
        margin-left: rem-calc(20);
        li{
            a{
                padding: rem-calc(10 15);
                text-align: left;
                min-width: rem-calc(210);
                font-size: rem-calc(14);
            }
        }

    }

}