.table {
    border:none;
    width: 100%;
    thead {
      text-transform: normal;
      text-align: left;
    }
    td {
      vertical-align: middle;
    }
    th,
    td {
      /*padding: rem-calc(16) !important;*/
      font-size:rem-calc(12);
    }
    thead tr{
      background: #fff !important;
      }
    tr.alt, tr.even, tr:nth-of-type(n) {
        background: #f2f2f2;
    }
    tr.alt, tr.even, tr:nth-of-type(2n) {
        background: #fff;
    }
    thead{
        tr:nth-of-type(n){
            &:before{
                background-color:#fff;
                content: '';
                height: 60px;
                @media #{$small-up} {
                    height: 50px;
                }
                width: 1000%;
                position: absolute;
                right: 0;
                z-index: -1;
            }
            &:after{
                background-color:#fff;
                content: '';
                height: 60px;
                @media #{$small-up} {
                    height: 50px;
                }
                width: 1000%;
                position: absolute;
                left: 0;
                z-index: -1;
            }

        }
    }
    tbody{
        tr:nth-of-type(n){
            &:after{
                background-color:#f2f2f2;
                content: '';
                height: 60px;
                @media #{$small-up} {
                    height: 50px;
                }
                width: 1000%;
                position: absolute;
                left: 0;
                z-index: -1;
            }
            &:before{
                background-color:#f2f2f2;
                content: '';
                height: 60px;
                @media #{$small-up} {
                    height: 50px;
                }
                width: 1000%;
                position: absolute;
                right: 0;
                z-index: -1;
            }
            td{
                height: 60px;
                @media #{$small-up} {
                    height: 50px;
                }

                &.id{
                    font-weight: bold;
                }
                &.date{
                    font-size: rem-calc(14);
                    strong{
                        font-weight: bold;
                    }
                }
                .actions{
                    display: flex;
                    justify-content: space-between;
                    div{
                        flex-grow:1;
                        text-align: center;
                    }
                    div:nth-of-type(1){
                        width: 0px;
                    }

                }
            }
        }
        tr:nth-of-type(2n){
            &:after{
                background-color:#fff;
            }
            &:before{
                background-color:#fff;
            }
        }
        /*tr:last-of-type{
            &:after{
                height: 60px;
                @media #{$small-up} {
                    height: 50px;
                }
            }
            &:before{
                height: 60px;
                @media #{$small-up} {
                    height: 50px;
                }
            }
        }   */

    }
}
.table-tracking {
    th {
        a{
            &.sort_sortable {
                background: url(img/arrow_up_down_sort.gif) no-repeat right 50%;
                padding-right: 20px;
            }
            &.sort_asc {
                background: url(img/arrow_up_down_sort_up.gif) no-repeat right 50%;
                padding-right: 20px;
            }
            &.sort_desc {
                background: url(img/arrow_up_down_sort_down.gif) no-repeat right 50%;
                padding-right: 20px;
            }
        }
    }
}
.table-index {
    th,
    td {
        padding-top: rem-calc(18) !important;
        padding-bottom: rem-calc(18) !important;
        font-size: rem-calc(16);
    }
    thead tr{
        background: #fff !important;
            th{
                font-size: rem-calc(12);
            }
        }
      tr.alt, tr.even, tr:nth-of-type(n) {
          background: #fff;
      }
      thead{
          tr:nth-of-type(n){
              &:before{
                  content: none;
              }
              th{
                border-bottom:1px solid #dbdbdb
             }

          }
      }
      tbody{
          tr:nth-of-type(n){
              &:after{
                content: none;
            }
              &:before{
                content: none;
            }
            td{
               border-bottom:1px solid #dbdbdb
            }

        }

    }
}

.table-analysis {
    tbody{
        tr{

            &.patient{
                font-weight: bold;
                background: #ddd;
                &:after{
                    background: #ddd;
                }
                &:before{
                    background: #ddd;
                }
            }
           /* td{
                &.col1{
                    width: 250px;
                }
            }*/
        }
    }
}
