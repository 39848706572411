

.main-header {
    background-image: linear-gradient(to bottom, #ffffff, #fdfdfd);

  .wrap{
    padding-left:0;
    padding-right:0;
  }
  .site-logo {
    background-color:$primary-color;
    height:70px;
    padding-top:10px;
    width: 325px;
    &:before{
      background-color:$primary-color;
      content: '';
      display: block;
      height:70px;
      width: 1005%;
      position: absolute;
      top: 0;
      left: -1000%;
    }
    a{
      display:inline-block;
      color:#282d51;
      position:relative;
      z-index:99;
    }
    .vall-dhebron {
      width: 139px;
      height: 40px;
      margin: 5px 0 6px 0;
      object-fit: contain;
      padding-left:0;

    }
    .recognised {
      width: 140px;
      height: 24px;
      margin: 25px 0 0 21px;
      object-fit: contain;
    }
  }

  .mobile{
    background-color:$primary-color;
    margin-bottom:2rem;

    .site-logo{
      width: auto;
      padding-top:rem-calc(10);
     /* &:after{
        background-color:$primary-color;
        content: '';
        display: block;
        height:70px;
        width: 1005%;
        position: absolute;
        top: 0;
        right: -1000%;
      }*/
      .vall-dhebron {
        width: 92px;
        margin: 0;
        padding-left:0;
      }
      .recognised {
        width: 112px;
        margin:18px 0 0 16px
      }
    }
  }


  .lang,
  .tools {
    display: none;
  }

  @media #{$medium-up} {
    h1 {
      width: 40%;
      float: left;
    }

    .tools {
      display: block;
      width: 60%;
      float: right;
      text-align: right;
      padding-top: 38px;
    }

    .lang {
      display: inline-block;
      margin-bottom: 0;

      li {
        display: inline;

        &.active a {
          background: $secondary-color;

          &:hover {
            text-decoration: none;
            cursor: default;

            abbr {
              cursor: default;
            }
          }
        }
      }

      a {
        display: inline-block;
        padding: 0 0.5em;
        line-height: 32px;

        &:hover {
          border: 0;
          text-decoration: underline;

          abbr {
            cursor: pointer;
          }
        }
      }
    }

    .share {
      display: inline-block;
      margin: 0 0 0 6px;
      vertical-align: top;
      list-style: none;

      li {
        float: left;
      }

      a {
        margin: 0 0 0 12px;
        display: inline-block;
      }
    }
  }
}
/*
.menu-trigger {
  position: absolute;
  top: 015px;
  right: $column-gutter/2;
  z-index: 20;
  text-transform: uppercase;
  text-align: center;
  font-size: rem-calc(12);
  background-color: transparent;
  margin:0;
  padding: 0;

  &:before {
    @extend .icon-menu-small;
    content: '';
    display: block;
    margin-bottom: 0;
  }

  @media #{$medium-up} {
    display: none;
  }
}*/
