p{
    margin-bottom: rem-calc(20);
}


.zoom {
  /*& img:hover {
    opacity: 0;
  }
  img {
    transition: opacity .5s;
    display: block;
    width: 100%;
  }
  background-position: 50% 50%;
  position: relative;
  width: 500px;
  overflow: hidden;*/
  cursor: zoom-in;
}
