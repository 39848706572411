// mixins

// Triangle
// --------
// We use this to create isosceles triangles
// - $triangle-size       - Used to set border-size. No default, set a px or em size.
// - $triangle-color      - Used to set border-color which makes up triangle. No default
// - $triangle-direction  - Used to determine which direction triangle points.
//                          Options: top, bottom, left, right
@mixin triangle($triangle-size, $triangle-color, $triangle-direction) {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border: inset $triangle-size;
  @if $triangle-direction == top {
    border-color: $triangle-color transparent transparent transparent;
    border-top-style: solid;
  }
  @if $triangle-direction == bottom {
    border-color: transparent transparent $triangle-color transparent;
    border-bottom-style: solid;
  }
  @if $triangle-direction == left {
    border-color: transparent transparent transparent $triangle-color;
    border-left-style: solid;
  }
  @if $triangle-direction == right {
    border-color: transparent $triangle-color transparent transparent;
    border-right-style: solid;
  }
}

// visually hidden
@mixin visually-hidden {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}

// underline on hover
@mixin hover {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

// treure klanders de llista
@mixin undolist {
  margin-left: 0;
  li {
    padding-left: 0;
    // margin-bottom: 0;
    &:before {
      display: none;
    }
  }
}

// Clearfix
// --------
@mixin cf {
  &:after {
    content: " ";
    display: table;
    clear: both;
  }
}

// @mixin line($color, $button-size, $transition) {
//   display: inline-block;
//   width: $button-size;
//   //height: $button-size/7;
//   height: 3px;
//   background: $color;
//   //border-radius: $button-size/14;
//   transition: $transition;
// }

// Stack https://every-layout.dev/layouts/stack/
// ---------------------------------------------

@mixin stack-smaller {
  > * + * { 
    margin-top: $spacing * 0.25;
  }
}
@mixin stack-small {
  > * + * {
    margin-top: $spacing * 0.5;
  }
}
@mixin stack {
  > * + * {
    margin-top: $spacing !important;
  }
}
@mixin stack-large {
  > * + * {
    margin-top: $spacing * 1.5;
  }
}
