html {
  scroll-behavior: smooth;
  overflow-x: hidden;

}


@media #{$small-only} {
   .columns {
     padding-left:rem-calc(32);
     padding-right:rem-calc(32);
   }
}


// Stack (https://every-layout.dev/layouts/stack/)
.stack-smaller > * + * {
  margin-top: $spacing * 0.25;
}
.stack-smaller-recursive * + * {
  margin-top: $spacing * 0.25;
}
.stack-small > * + * {
  margin-top: $spacing * 0.5;
}
.stack-small-recursive * + * {
  margin-top: $spacing * 0.5;
}
.stack > * + * {
  margin-top: $spacing;
}
.stack-recursive * + * {
  margin-top: $spacing;
}
.stack-large > * + * {
  margin-top: $spacing * 2;
}
.stack-large-recursive * + * {
  margin-top: $spacing * 2;
}
.stack-larger > * + * {
  margin-top: $spacing * 2.5;
}
.stack-larger-recursive * + * {
  margin-top: $spacing * 2.5;
}
.stack-largest > * + * {
  margin-top: $spacing * 3;
}
.stack-largest-recursive * + * {
  margin-top: $spacing * 3;  
}


// text en columnes
.text-2-col {
  @media #{$medium-up} {
    column-count: 2;
  }
}
.text-3-col {
  @media #{$medium-up} {
    column-count: 2;
  }
  @media #{$large-up} {
    column-count: 3;
  }
}

.top-heading + .row {
  @media #{$small-only} {
    margin-top: 0;
  }
}



// .main-content {
//   @include tran;

//   .has-opacity & {
//     @include op;
//   }

//   .lt-ie9 & {
//     > .row {
//       width: auto !important;
//     }
//   }
// }

// .side-content {
//   @include grid-column(12);
// }

// @media #{$medium-up} {
//   .container {
//     @include grid-row;
//   }

//   .main-nav-container {
//     @include grid-column(3);
//   }

//   .main-content {
//     @include grid-column(9);

//     &.full-width {
//       @include grid-column(12);

//       > .row {
//         @include grid-row($behavior: nest);

//       }
//     }
//   }

//   // columna dreta tipus 1
//   .right-col-1 {
//     .main-column {
//       @include grid-column(8);
//     }

//     .side-content {
//       @include grid-column(4);
//     }
//   }

//   // columna dreta tipus 1 full-width
//   .right-col-1 .full-width {
//     .main-column {
//       @include grid-column(6);
//     }

//     .side-content {
//       @include grid-column(6);
//     }
//   }

//   // columna dreta tipus 2
//   .right-col-2 {
//     .main-column {
//       @include grid-column(7);
//     }

//     .side-content {
//       @include grid-column(5);
//     }
//   }

//   // columna dreta tipus 2 full-width
//   .right-col-2 .full-width {
//     .main-column {
//       @include grid-column(6);
//     }

//     .side-content {
//       @include grid-column(6);
//     }
//   }

//   // columna dreta tipus 3
//   .right-col-3 {
//     .main-column {
//       @include grid-column(6);
//     }

//     .side-content {
//       @include grid-column(6);

//       .col {
//         @include grid-column(6);
//       }
//     }
//   }

//   // dues columnes
//   .two-cols {
//     .main-column {
//       @include grid-column(6);
//     }
//   }


// }
