.simple-grid-list {
  margin-left: -$spacing * 0.25;
  margin-right: -$spacing * 0.25;
  display: flex;
  flex-wrap: wrap;
  @media #{$medium-up} {
    margin-left: -$spacing;
    margin-right: -$spacing;
  }
  > li {
    @include stack-small;
    margin: 0;
    margin-bottom: $spacing * 0.25;
    @media #{$medium-up} {
        margin-bottom: $spacing * 2;
    }
    padding: $spacing * 0.25;
    width: 100%;
    // no flexbox fallback
    .ie9 & {
      display: inline-block;
      vertical-align: top;
    }
    &:before {
      display: none;
    }
    &:last-child {
      margin: 0;
    }
    @media screen and (min-width: 30em) {
      width: 50%;
    }
    @media #{$medium-up} {
      padding: $spacing;
    }
  }

  // fila de 2
  &--2 {
    margin-left: -$spacing ;
    margin-right: -$spacing ;
    > li {
        margin:0;
        padding-bottom: 0;
  }
}

  // fila de 3
  &--3 {
      margin-left:0;
      margin-right:0;
    > li {
        padding:0;
      @media #{$medium-up} {
        width: 33.33%;
      }
    }
  }

  // fila de 4
  &--4 {
    > li {
        margin:0;
        padding-bottom: 0;
      @media #{$large-up} {
        width: 25%;
      }
    }
  }
  // fila auto
  &--auto {
    margin:0;
    > li {
      padding:0 $spacing 1rem 0;
      margin-right:$spacing *0.25;
      border-bottom: 1px solid #ccc;
      flex-grow: 1;
      width: 45%;
      @media #{$medium-up} {
        margin-right:$spacing;
        width: auto;
      }
      &:last-child {
        margin-bottom: $spacing*2;
      }
    }
  }

  // fila auto
  &--dashboard {
    margin:0;

    > li {
      padding:0 $spacing 1rem 0;
      margin-bottom: 1rem;
      border-bottom: 1px solid #ccc;
      flex-grow: 1;
      font-size: 1rem;
      @media #{$medium-up} {
        width: 100%;
      }
      &:first-child {
        border-color: #e79f28;
        span{
          background-color: #e79f28;
          border-color: #e79f28;
        }
      }
      &:last-child {
        margin-bottom: $spacing;
        border:none
      }
      h3{
        font-size: rem-calc(22);
        margin:0
      }
      p{
        margin:0
      }
      a{
        color:$tertiary-color;
        font-weight: bold;
      }
      date{
        color:#999
      }
    }
  }

  h2 {
    hyphens: auto;
  }

  ul {
    font-weight: normal;
    li {
      border-bottom: 1px solid #979797;
      margin-top: 0;
      padding-top: $spacing * 0.25;
      padding-bottom: $spacing * 0.25;
      font-size: rem-calc(16) !important;
      @media #{$xlarge-up} {
        font-size: rem-calc(18) !important;
      }
      &::before {
        top: 1em !important;
      }
    }
  }
}

// Classes pel Houdini:

// dashboard
.simple-grid-list-dashboard {
  @extend .simple-grid-list;
  @extend .simple-grid-list--dashboard;

}

// columnes
.simple-grid-list-auto {
  @extend .simple-grid-list;
  @extend .simple-grid-list--auto;

}
// 2 columnes
.simple-grid-list-2 {
  @extend .simple-grid-list;
  @extend .simple-grid-list--2;

}
// 3 columnes
.simple-grid-list-3 {
  @extend .simple-grid-list;
  @extend .simple-grid-list--3;
}
// 4 columnes
.simple-grid-list-4 {
  @extend .simple-grid-list;
  @extend .simple-grid-list--4;
}
