.main-content{
  min-height: 100vh;
}


#sending-container {
	margin: 0 auto;
	z-index: 100000;
	top: 25%;
	text-align: center;
	display: none;
	width: 200px;
	position: fixed;
	left: 45%;
	background-color: #fff;
	border-radius: 20px;
	padding: 20px;
    border:2px solid $tertiary-color;

    h3{
        font-size: 1rem;
        margin: 0;
    }
    
    .progress {
    	margin-top: 1rem;
	    .meter {
			background: #6775da;
			opacity: .8;
		}
	}
    
}


.reveal-modal {
	p {
		margin-bottom: 1.25rem;
	}
	strong {
		font-weight: 700;
	}
}

.visita{
	padding-left:rem-calc(5);
}
.uneditable-input {
    border: 1px solid #282d51;
    border-radius: 20px;
    padding: 5px 20px;
    text-decoration: none;
    display: inline-block;
    text-align: center;
    background-color: #dfdfdf;
    border-color:#ccc;

}

#upload_overlay {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
	background: rgba(0,0,0,.2);
	width: 100%;
	height: 100%;
}


.delete-filter {
	position: relative;
	
	.delete-filter-a {	
	    color: #aaa;
	    cursor: pointer;
	    font-size: 1.75rem;
	    font-weight: 700;
	    line-height: 1;
	    position: absolute;
	    right: 0;
	    top: -.125rem;
    }
}