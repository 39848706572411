ul.tabs {
    margin-left:0;
    li{
        padding-left:0;
        padding-right:rem-calc(12);
        margin-bottom:rem-calc(4);
        @media #{$medium-up} {
            margin-bottom:rem-calc(7);
        }
        display:inline-block;
        &::before{
            content: none;
        }
        a{
            border-top: 1px solid #979797;
            border-left: 1px solid #979797;
            border-right: 1px solid #979797;
            border-radius: 3px 3px 0px 0px;
            text-decoration: none;
            padding: rem-calc(10 20 8);

            @media #{$medium-up} {
                padding: rem-calc(15 35 11);

            }
            &:hover {
                color:$tertiary-color;
            }
         }

        &.active a{
            background-color: #fff;
            -webkit-background-clip: unset;
        }
    }
    &.content{
        margin-bottom:rem-calc(30) !important;
        li{
            border-right: 1px solid #979797;
            padding-right: rem-calc(35);
            min-width: 130px;
            margin-right: rem-calc(32);
            padding-bottom: rem-calc(20);
            margin-bottom: 0;

            &:last-child{
                border-right: none;
                margin-right: rem-calc(0);
            }
            a{
                border:none;
                padding:0;
                font-size: rem-calc(16);
            }

            &.active {
                padding-bottom: rem-calc(8);

                a{
                    font-weight: bold;
                    &::after{
                        content:'';
                        border-bottom: 7px solid $secondary-color;
                        display: block;
                        width: 100%;
                        margin-top: 5px;
                    }
                }
            }
        }
    }
}

ul.stats{
    margin-top:rem-calc(20);
    margin-bottom:rem-calc(20);
    li{
        display:inline-block;
        span{
            font-weight: bold;
            display: block;
            text-align: center;
            font-size:rem-calc(22);
        }
        a{
            margin:0;
        }
    }

}
