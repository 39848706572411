ul.pagination {
  padding-top: 3.5rem;
  li {
    padding: 0;
    margin: 0;
    display: inline-block;
    font-size: rem-calc(14);
    vertical-align: middle;
    float: none;
    border:1px solid #dddddd;
    height: auto;

    &:before {
      display: none;
    }
    &:hover {
      a {
        color: $white;
      }
    }

    &.hide {
      display: none;
    }

    &:last-child {
      a {
        border-right: 0;
      }
    }

    a {
      border-radius: 0;
      transition: none;
      background: $light-gray;
      padding:8px 16px; 
      color:#000;

      &:link:hover { 
        color: $white;
        border-bottom: 0;
        text-decoration: none;
      }
    }

    &.unavailable {
      a {
        background: none;
      }
    }

    &.arrow {
      a {
        padding-top: 0;
        padding-bottom: 0;
        background: none;
      }

      img {
        width: 29px;
      }

      span {
        display: none;
      }

      &.prev {
        a {
          padding-left: 0;
        }
      }

      &.next {
        a {
          padding-right: 0;
        }
      }
    }
  }
/*
  @media #{$small-up} {

    li {
    
      a {
        font-size: rem-calc(17);
        padding-left: 0.75rem;
        padding-right:  0.75rem;
      }

      &.hide {
        display: inline-block;
      }

      &.arrow {
        margin-top: 3px;

        a {
          border-right: 0;
        }

        img {
          width: 40px;
        }

        &.prev {
          float: left;
        }

        &.next {
          float: right;
        }
      }
    }
  }

  @media #{$medium-up} {
    li {
      margin-top:0;
      padding:0;

      &.arrow {
        span {
          display: inline;
        }

        a:link {
          &:hover {
            text-decoration: underline;
            color: $body-font-color;
          }
        }
      }

      &.prev {
        img {
          margin-right: 10px;
        }
      }

      &.next {
        img {
          margin-left: 10px;
        }
      }
    }
  } */ 
}
