/*form{
	input[type="text"],
	input[type="email"],
	input[type="password"],
	input[type="search"],
	input[type="url"],
	input[type="time"],
	textarea,
	select {
	  color: #313131;
	  font-weight: normal;
	  background-color: #f2f2f2;
	  border: 1px solid #f2f2f2;
	  padding: $spacing * 0.25 $spacing * 0.75;
	  width: 100%;
	  height: rem-calc(36);
	  border-radius:rem-calc(15);
	  box-shadow: none;
	  transition: background-color 0.1s;
	  &.error {
		border-color: #f00;
	  }
	  &:focus {
		background-color: #fefefe;
	  }
	}
	textarea {
	  height: $spacing * 10;
	}
}*/

form .row .row .column, form .row .row .columns {
    padding: 0 .75rem;
    @media #{$medium-up} {
        padding: 0 .5rem;
    }
}

.form-steps {
	.audible {
	   display: none;
	}
	.steps ul {
	    cursor: pointer;
	    display: inline-block;
	    font-size: 0px;
	    overflow: hidden;
	    border: 1px solid #ccc;
	    line-height: 1;
	    box-sizing: border-box;
	    padding-left: 0;
	    list-style: none;
        margin: 0 0 1rem 0;
	    li {
	        margin: 0;
		    display: inline-block;
		    position: relative;
		    padding: 1em 2em 1em 3em;
		    vertical-align: top;
		    background-color: #fafafa;
		    box-sizing: border-box;
		    font-size: 1rem;
		    border-right: 1px solid #fff;
		    &.last {
		      border-right: none;
		    }
		    &:before {
		      display: none;
		    }
		    &:after {
			    display: block;
			    position: absolute;
			    z-index: 2;
			    content: "";
			    top: 0.36em;
			    right: -1.2em;
			    width: 2.3em;
			    height: 2.3em;
			    background-color: #fafafa;
			    transform: rotate(-45deg);
			    border-right: 1px solid #fff;
			    border-bottom: 1px solid #fff;
			}
			&:first-child {
			    padding-left: 2.5em;
			}
			&:last-child {
			    padding-right: 2.5em;
			}

			&:hover,
			&:hover:after {
			    background-color: #e6f4f9;
			}
			&.done,
            &.done:after {
			     background: #9dc8e2;
			}
			&.current,
			&.current:after {
			  background-color: #007295;
			  color: #fff;
			}
			&.current a {
			  color: #fff;
			}

			&.disabled,
			&.disabled:after {
			  background-color: #fff;
			  color: #aaa;
			  cursor: default;
			}

		}
	}
	.content {
	   .title {
	       display: none;
	   }
	}
    .actions {
        margin-top: 1em;
        ul {
	        list-style: none;
	        margin: 0;
	        li {
	           display: inline-block;
               padding-left: 0em;
               padding-right: 1em;
		        &:before {
	              display: none;
	            }
	            &.disabled {
	                display: none;
	            }

	            a {
	                @include button;
	                padding: .5rem 1rem .5rem 1rem;
	                font-size: .875rem;
	            }
	        }
        }
    }
}

.form-search{
	margin:0 0 rem-calc(20) 0;
	select, input[type="text"],input[type="date"]{
		padding:  rem-calc(5 20 5 15);
		border:1px solid $primary-color;
		border-radius: 20px;
		color:$secondary-color;
		display:inline-flex;
		width:auto;
		margin-right:rem-calc(13);
		font-weight: bold;
		font-size: rem-calc(14);
	}
	option{
		font-size: rem-calc(12);
	}
	input[type="text"],input[type="date"]{
		padding:  rem-calc(5 15 5 15);
		border-color:#f2f2f2;
		background-color: #f2f2f2;
		color:#818181;
		text-align: center;
		font-weight: normal;
		box-shadow: none;
		&.input-small{
			width: rem-calc(180);
		}
	}
	/*button{
		display:inline-flex;
	}*/
}

.form-vertical{
	fieldset{
		border:none;
		padding: 0;
	}
	input[type="radio"]{
		appearance: button;
		margin-right: 10px;
	}
}

.form-wrapper {
	background-color:$secondary-color;
	padding: $spacing;
	@media #{$medium-up} {
	  padding: $spacing * 1.5;
	}
	@media #{$large-up} {
	  padding: $spacing * 2;
	}
}

.houdiniForm {
	&:not(.filters-form) {
	/*  @include stack;*/
	 /* fieldset {
		@include stack;
	  }*/
	}
	.form-body {
	  @include stack;
	}
	.form-actions{
		margin-top:rem-calc(20);
		margin-bottom:rem-calc(20);
	}
	fieldset {
	  border: 0;
	  margin-left: 0;
	  margin-right: 0;
	  padding: 0;
	  .form-actions{
			@include stack ;
			button{
				margin-right:rem-calc(20);
			}
	  }
	  .controls{
		min-height: 40px;
	    }
        .control-group{
            label {
                margin-bottom: rem-calc(10);
                &.file{
                    display: flex;
                    span{
                        padding:4px 0;
                        &.marc{
                            padding: 4px 20px;
                        }
                    }
                }
            }
        }

	  &.qualitatiu, &.quantitatiu{
		.control-group{
			margin-bottom:rem-calc(20);
			label {
              margin-bottom: rem-calc(10);
			  font-size: rem-calc(22);
              @media #{$medium-up} {
                font-size: rem-calc(18);
             }
			  &.radio{
				font-size: rem-calc(16)  !important;
				font-weight: bold !important;
				padding: rem-calc(8 0);
				margin:0 ;
			  }
			}
			.controls{
				min-height: 36px;
                margin-bottom:30px;
				.add-on{
                    font-weight:bold;
				}
				.input-small{
				  font-weight:bold;
			     }
				select{
				  margin:rem-calc(0 0 20px);
				  color:$secondary-color;
			  }

			}
			> .control-group{
			  label {
				 font-size: rem-calc(14);
                 @media #{$medium-up} {
                    font-size: rem-calc(16);
                }
				  font-weight: normal;
				  margin-bottom:rem-calc(10);
				  &.inline {
					display: block;
				}
			  }
			  p{
				  font-weight:bold;
				  display:block;
			  }
			}
		}
		p{
			  display:block;
              @media #{$medium-up} {
                display:inline-flex;
            }
			  margin:0 0 20px 20px
		}
        .uneditable-input, .uneditable-textarea{
            border: 1px solid #282d51;
            border-radius: 20px;
            padding: 5px 20px;
            margin-bottom:20px;
            text-decoration: none;
            display: inline-block;
            text-align: center;
            background-color: #dfdfdf;
            border-color:#ccc;
            flex-grow:1;
            width: 100%;
            font-size: rem-calc(12);
            @media #{$medium-up} {
                font-size: rem-calc(16);
            }

        }
        .uneditable-textarea{
            min-height: 200px;
            text-align: left;
        }

	  }
	  &.quantitatiu{

		  .control-group{
			.controls{
				.input-prepend{

                    display:flex;
				  }
				  .add-on{
					 /* width: 15%;
					  display: inline-block;*/
                      flex-grow:1;
                      margin-right: 20px;
                 }

				 input[type="text"]{
					/*width: 25%;*/
					text-align: center;
					display: inline-block;
					margin:rem-calc(0 0 20px);
					color:$secondary-color;
                    flex-grow:1;

				}

			}
		  }
	  }

	  > div {
		 margin-bottom: $spacing * 1;
		&:last-child {
		  margin-bottom: 0;
		}
		.columns {
		  @media screen and (max-width: 49.9375em) {
			margin-top: $spacing;
			&:first-child {
			  margin-top: 0;
			}
		  }
		}
	  }
	}
	legend {
	  font-weight: bold;
	  font-size:rem-calc(18);
	   margin-bottom: $spacing;
	  small {
	  	font-weight: normal;
	  	margin-left: 1rem;
	  	color: #666;
	  }
	}
	label {
	  display: block;
	  margin-bottom: $spacing * 0.25;
	  font-weight: bold;
	  color:#000;
	  font-size: rem-calc(16);
	  &.inline {
	  	margin-right: 2.5rem  !important;
	  	display: inline;
	  }

	  &.error {
		text-transform: none;
		letter-spacing: 0;
		font-weight: normal;
		color: #d60000;
		margin-top: $spacing * 0.25;
		font-size: rem-calc(14) !important;
	  }
	  .form-wrapper & {
		color: #fff;
	  }
	  .info {
		text-transform: none;
		font-weight: 300;
		letter-spacing: 0;
	  }
	}
	.simple-label label {
	  @extend .simple-label;
	}
	input[type="text"],
	input[type="email"],
	input[type="password"],
	input[type="search"],
	input[type="url"],
	input[type="time"],
	input[type="number"],
	input[type="date"],
	textarea,
	select {
	  color: #313131;
	  font-weight: normal;
	  background-color: #fbfcfc;
	  border: 1px solid $primary-color;
	  padding: 0 $spacing * 0.75;
	  width: 100%;
	  height: rem-calc(36);
	  border-radius:rem-calc(20);
	  box-shadow: none;
	  transition: background-color 0.1s;
	  &.error {
		border-color: #f00;
	  }
	  &:focus {
		background-color: #fefefe;
	  }

	  &.disabled,
	  &[disabled="disabled"] {
	    background: #dfdfdf;
	    border-color:#ccc;
  	    cursor: not-allowed;
	  }
	}
	input[type="number"]{
		padding: $spacing * 0.25 5px $spacing * 0.25 $spacing * 0.75;
	}

	textarea {
	  height: $spacing * 10;
      padding: $spacing * 0.5;
	}
	.input-patientcode {
		input[type="text"] {
			display: inline;
			width: 150px;
		}
		&-prefix {
			font-size: 1.125em;
			margin-right: .75em;
		}
	}

	// checkboxes & radiobuttons (https://css-tricks.com/custom-styling-form-inputs-with-modern-css-features/)
	// CAL REVISAR I SIMPLIFICAR CODI
	@supports (-webkit-appearance: none) or (-moz-appearance: none) {
	  input[type="checkbox"],
	  input[type="radio"] {
		--active: #4e589c;
		--active-inner: #fff;
		--focus: 3px lightskyblue;
		--border: #282d51;
		--border-hover: #282d51;
		--background: #fff;
		--disabled: #f6f8ff;
		--disabled-inner: #e1e6f9;
		-webkit-appearance: none;
		-moz-appearance: none;
		height: $spacing;
		outline: none;
		display: inline-block;
		vertical-align: top;
		position: relative;
		margin: 0;
		// cursor: pointer;
		border: 1px solid var(--bc, var(--border));
		background: var(--b, var(--background));
		transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
		&.ok{
			--active:#82c347;
		}
		&.rebutjat{
			--active:#d84848;
		}
		&.incident{
			--active:#e1752e;
		}
		&:after {
		  content: "";
		  display: block;
		  left: 0;
		  top: 0;
		  position: absolute;
		  transition: transform var(--d-t, 0.3s) var(--d-t-e, ease),
			opacity var(--d-o, 0.1s);
		}
		&:checked {
		  --b: var(--active);
		  --bc: var(--active);
		  --d-o: 0.3s;
		  --d-t: 0.6s;
		  --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
		}
		// &:disabled {
		//   --b: var(--disabled);
		//   cursor: not-allowed;
		//   opacity: 0.9;
		//   &:checked {
		//     --b: var(--disabled-inner);
		//     --bc: var(--border);
		//   }
		//   & + label {
		//     cursor: not-allowed;
		//   }
		// }
		&:hover {
		  &:not(:checked) {
			&:not(:disabled) {
			  --bc: var(--border-hover);
			}
		  }
		}
		&:focus {
		  box-shadow: 0 0 0 var(--focus);
		}
		width: $spacing;
		&:after {
		  opacity: var(--o, 0);
		}
		&:checked {
		  --o: 1;
		}
	  }
	  input[type="checkbox"] {
		border-radius: 2px;
		&:after {
		  width: 7px;
		  height: 11px;
		  border: 2px solid var(--active-inner);
		  border-top: 0;
		  border-left: 0;
		  left: 8px;
		  top: 4px;
		  transform: rotate(var(--r, 20deg));
		}
		&:checked {
		  --r: 43deg;
		}
	  }
	  input[type="radio"] {
		border-radius: 50%;
		&:after {
		  width: 16px;
		  height: 16px;
		  border-radius: 50%;
		  background: var(--active-inner);
		  opacity: 1;
		  transform: scale(var(--s, 0.7));
		  top: 3px;
		  left: 3px;
		}
		&:checked {
		  --s: 0.5;
		}
	  }
	}
	input[type="checkbox"],
	input[type="radio"] {
	  margin-right: $spacing * 0.25;
	}
  }
  input.date {
	background-image: url("img/ico-calendario.svg");
	background-repeat: no-repeat;
	background-position: 98% 50%;
	background-position: calc(100% - 4px) 50%;
  }


  // filters
  .filters-form {
	fieldset {
	  background-color: #ededed;
	  border-radius: 8px;
	  padding: $spacing * 2 $spacing * 0.75 0;
	  position: relative;
	}
	legend {
	  text-transform: uppercase;
	  font-weight: 900;
	  font-size: 1rem;
	  position: relative;
	  top: 2rem;
	}
	button[type="button"] {
	  float: right;
	  margin-top: -3rem;
	  padding: 0;
	  border: 0;
	  background: transparent url("img/bt-abrir-filtro.svg") no-repeat;
	  width: rem-calc(34);
	  height: rem-calc(34);
	  transition: transform 0.15s ease-in-out;
	  &[aria-expanded="true"] {
		transform: rotate(0.5turn);
	  }
	}
	ul {
	  @include undolist;
	  margin-bottom: $spacing;
	}
	input[type="checkbox"] {
	  border-color: #fff;
	  &:checked + span {
		font-weight: 900;
	  }
	}
	label.simple-label {
	  font-size: rem-calc(14);
	  font-weight: normal;
	  span {
		float: right;
		margin-top: 0;
	  }
	}
	abbr[title] {
	  border-bottom: none;
	  text-decoration: none;
	}
  }

  // documents
  .js .docs-form span.info {
	display: none;
  }

  // forms-steps

  .form-steps {
	  .audible {
		 display: none;
	  }
	  .steps ul {
		  cursor: pointer;
		  display: inline-block;
		  font-size: 0px;
		  overflow: hidden;
		  border-bottom: 1px solid #d92216;
		  line-height: 1;
		  box-sizing: border-box;
		  padding-left: 0;
		  list-style: none;
		  margin: 0 0 1rem 0;
		  li {
			  margin: 0;
			  display: inline-block;
			  position: relative;
			  padding: rem-calc(15 10);
			  vertical-align: top;
			  //background-color: #fafafa;
			  box-sizing: border-box;
			  font-size: rem-calc(14);
			  border-right: 1px solid #fff;
			  &.last {
				border-right: none;
			  }
			  &:before {
				display: none;
			  }
			  &:after {
				  content: '›';
				  display: block;
				  position: absolute;
				  right: 0px;
				  top: 15px;
			  }
			  &:first-child {
				  padding-left: 0;
			  }
			  &:last-child {
				&:after {
				  content: none;
				}
			  }
			   a {
				  @include hover;
				  background: none;
				  -webkit-text-fill-color: #000;
				  color: #000;
				  letter-spacing: 1px;
				  font-weight: normal;
			  }
			  &:hover,
			  &:hover:after {
				  background-color: #e6f4f9;
			  }
			  &.done a {
				  color: #d92216;
				  -webkit-text-fill-color: #d92216;

			  }
			  &.current a {
				color: #d92216;
				-webkit-text-fill-color: #d92216;
			  }

			  &.disabled,
			  &.disabled:after {
				//background-color: #fff;
				cursor: default;
			  }
		  }
	  }
	  p {
			font-size: rem-calc(16);
			margin-bottom: rem-calc(15);
		  }
	  hr {
		margin-top: rem-calc(15);
		margin-bottom: rem-calc(15);
	  }
	  .content {
		 .title {
			 display: none;
		 }
	  }
	  .actions {
		  margin-top: 1em;
		  ul {
			  list-style: none;
			  margin: 0;
			  li {
				 display: inline-block;
				 padding-left: 0em;
				 padding-right: 1em;
				  &:before {
					display: none;
				  }
				  &.disabled {
					  display: none;
				  }

				  a {
					  border-color: #d92216;
					  display: inline-block;
					  border: 1px solid;
					  padding: 1.125rem 1.5rem;
					  text-decoration: none;
					  color: #d92216;
					  -webkit-text-fill-color: #d92216;
					  background: #fff;
					  font-size: .75rem;
					  line-height: 1.2;
					  font-weight: 900;
					  text-align: center;
					  letter-spacing: 2.57px;
					  text-transform: uppercase;
					  -webkit-transition: .15s ease-out;
					  transition: .15s ease-out;
					  -webkit-transition-property: background-color,color;
					  transition-property: background-color,color;
					  border-radius: 4px;
					  &:hover {
						background: #d92216;
						color: #fff;
						-webkit-text-fill-color: #fff;
					  }
				  }
			  }
		  }
	  }
	  label {
		margin-top: rem-calc(15);
	  }
	  .type-label {
		font-size: rem-calc(16);
		margin-top: rem-calc(15);
	  }
   }
p.help-block-units {
    color: #666;
    font-size: .85rem;
    padding-left: .25rem;
}

.controls {
	position: relative;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button
  {
	-webkit-appearance: none;
	margin: 0;
  }

  input[type=number]
  {
	-moz-appearance: textfield;
  }

  .controls input[type=number] {
	float: left;
	padding-left: 20px;
  }

  .controls input[type=number]:focus {
	outline: 0;
  }

  .quantity-nav {
	float: left;
	position: relative;
	height: 38px;
	right: 35px;
	top: -5px;
  }

  .quantity-button {
	position: relative;
	cursor: pointer;
	color: $primary-color;
 }

  .quantity-button.quantity-up {
	position: absolute;
	top: 0;
	&::after{
		display: inline-block !important;
		content: "";
		display: block;
		width: 0;
		height: 0;
		border: inset 0.5rem;
		border-color: #999 transparent transparent transparent;
		border-top-style: solid;
		margin-left: 0.2rem;
		top: 4px;
		position: relative;
		left: 5px;
		transform: rotate(180deg);

	  }
  }

  .quantity-button.quantity-down {
	position: absolute;
	bottom: -5px;
	&::after{
		display: inline-block !important;
		content: "";
		display: block;
		width: 0;
		height: 0;
		border: inset 0.5rem;
		border-color: #999 transparent transparent transparent;
		border-top-style: solid;
		margin-left: 0.2rem;
		top: 4px;
		position: relative;
		left: 5px;
	  }
  }
