.badge {
  display: inline-block;
  text-decoration: none;
 /* margin-bottom: $spacing;
  margin-top: $spacing;*/
 padding: 5px 10px;
  /*text-transform: uppercase;*/
  text-align: center;
  color: #fff;
  background-color: $primary-color;
  transition: background-color 0.15s ease-in-out;
  font-size: rem-calc(14);
  font-weight: 400;
  border: 1px solid $primary-color;
  border-radius: 20px;
  width: 35px;
  /*height: 25px;*/
  font-size: 12px;
  display:inline-block;
 /* &:hover,
  &:active {
    text-decoration: none;
    background-color: $secondary-color;
    color:#fff;
  }*/
  &-info{
    border: 1px solid $primary-color;
    background-color: $primary-color;
    padding: 2px;
  }
  &-success{
		background-color: $success-color;
    border-color: $success-color;
    padding: 2px;
  }
  &-important{
    border: 1px solid $important-color;
    background-color: $important-color;
    padding: 2px;
  }
  &-warning{
    border: 1px solid $warning-color;
    background-color: $warning-color;
    padding: 2px;
  }
  &.count{
    margin:0;
    font-size: rem-calc(16);
    font-weight: bold;
    vertical-align: middle;
    width: 50px;
  }
	&.export{
		display: block;
		margin:10px auto;
	}
	&.success{
		background-color: $success-color;
    border-color: $success-color;
    padding:rem-calc(0);
    background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2214%22%20height%3D%2212%22%20viewBox%3D%220%200%2014%2012%22%3E%0A%20%20%20%20%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%0A%20%20%20%20%20%20%20%20%3Cg%20fill%3D%22%23FFF%22%20fill-rule%3D%22nonzero%22%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%3Cg%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3Cg%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3Cpath%20d%3D%22M0%206.729L4.742%2011.346%2014%202.331%2011.601%200%204.731%206.674%202.399%204.398z%22%20transform%3D%22translate(-562%20-538)%20translate(562%20538)%22%2F%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3C%2Fg%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%3C%2Fg%3E%0A%20%20%20%20%20%20%20%20%3C%2Fg%3E%0A%20%20%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 15px 13px;
    display: block;
    width: 35px;
    height: 25px;
    margin: auto;
    }


}





