.navigation{
  li {
    display:inline-table;
    padding:rem-calc(5 10);
   /* margin-right:10px;*/
    font-size: rem-calc(14);
    &.has-dropdown{
      >a::after {
        display: inline-table !important;
        content: "";
        display: block;
        width: 0;
        height: 0;
        border: inset 0.4rem;
        border-color: #282d51 transparent transparent transparent;
        border-top-style: solid;
        margin-left: 0.2rem;
        top: 0;
        position: relative;
        left: 5px;
        }
      .dropdown{
        display: none;
        position: absolute;
        z-index: 99;
        margin:0;
        padding: 4px 0px;
        li{
          display: block;
          border: none;
          background-color: #fff;
          border-radius: 0;
          padding: rem-calc(5 15);
          opacity: 0.9;
        }
      }
      &:hover{
        .dropdown{
          display:block;
        }
      }
    }
    a{
      color:$primary-color;
      font-size: rem-calc(14);
    }
    .user{
      background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2216%22%20viewBox%3D%220%200%2012%2016%22%3E%0A%20%20%20%20%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%0A%20%20%20%20%20%20%20%20%3Cg%20fill%3D%22%23282D51%22%20fill-rule%3D%22nonzero%22%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%3Cg%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3Cpath%20d%3D%22M10.863%2010.74L8.587%209.26l.858-1.573c.192-.346.304-.758.305-1.197V4c0-1.933-1.567-3.5-3.5-3.5S2.75%202.067%202.75%204v2.49c0%20.439.113.851.312%201.21l-.007-.013.858%201.573-2.276%201.48C.95%2011.19.5%2011.957.5%2012.83v2.67H12v-2.665-.004c0-.873-.449-1.641-1.128-2.086l-.01-.006zM11%2014.5H1.5v-1.665-.002c0-.524.27-.985.677-1.252l.006-.003%203.052-1.984-1.302-2.385C3.818%207%203.751%206.754%203.75%206.49V4c0-1.381%201.12-2.5%202.5-2.5S8.75%202.62%208.75%204v2.49c0%20.263-.068.51-.187.726l.004-.008-1.302%202.386%203.052%201.984c.414.27.683.731.683%201.254V14.5z%22%20transform%3D%22translate(-983%20-45)%20translate(983%2045)%22%2F%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%3C%2Fg%3E%0A%20%20%20%20%20%20%20%20%3C%2Fg%3E%0A%20%20%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A");
      background-repeat: no-repeat;
      padding:  rem-calc(5 15 5 35);
      border:1px solid $primary-color;
      border-radius: 20px;
      display: block;
      background-position: 15px 8px;
      &:hover{
        background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2216%22%20viewBox%3D%220%200%2012%2016%22%3E%0A%20%20%20%20%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%0A%20%20%20%20%20%20%20%20%3Cg%20fill%3D%22%23ffffff%22%20fill-rule%3D%22nonzero%22%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%3Cg%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3Cpath%20d%3D%22M10.863%2010.74L8.587%209.26l.858-1.573c.192-.346.304-.758.305-1.197V4c0-1.933-1.567-3.5-3.5-3.5S2.75%202.067%202.75%204v2.49c0%20.439.113.851.312%201.21l-.007-.013.858%201.573-2.276%201.48C.95%2011.19.5%2011.957.5%2012.83v2.67H12v-2.665-.004c0-.873-.449-1.641-1.128-2.086l-.01-.006zM11%2014.5H1.5v-1.665-.002c0-.524.27-.985.677-1.252l.006-.003%203.052-1.984-1.302-2.385C3.818%207%203.751%206.754%203.75%206.49V4c0-1.381%201.12-2.5%202.5-2.5S8.75%202.62%208.75%204v2.49c0%20.263-.068.51-.187.726l.004-.008-1.302%202.386%203.052%201.984c.414.27.683.731.683%201.254V14.5z%22%20transform%3D%22translate(-983%20-45)%20translate(983%2045)%22%2F%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%3C%2Fg%3E%0A%20%20%20%20%20%20%20%20%3C%2Fg%3E%0A%20%20%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A");
        background-color: $primary-color;
        color: #fff;
      }
    }
    .logout{
      background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2213%22%20height%3D%2216%22%20viewBox%3D%220%200%2013%2016%22%3E%0A%20%20%20%20%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%0A%20%20%20%20%20%20%20%20%3Cg%20fill%3D%22%23282D51%22%20fill-rule%3D%22nonzero%22%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%3Cg%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3Cpath%20d%3D%22M8.206%202.74v1.045C10.33%204.518%2011.863%206.58%2011.863%209c0%203.033-2.406%205.5-5.363%205.5-2.957%200-5.362-2.467-5.362-5.5%200-2.421%201.533-4.482%203.656-5.215V2.74C2.126%203.505.163%206.022.163%209c0%203.584%202.843%206.5%206.337%206.5%203.494%200%206.338-2.916%206.338-6.5%200-2.978-1.963-5.495-4.632-6.26z%22%20transform%3D%22translate(-1130%20-45)%20translate(1130%2045)%22%2F%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3Cpath%20d%3D%22M6.013%200.5L6.987%200.5%206.987%209%206.013%209z%22%20transform%3D%22translate(-1130%20-45)%20translate(1130%2045)%22%2F%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%3C%2Fg%3E%0A%20%20%20%20%20%20%20%20%3C%2Fg%3E%0A%20%20%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A");
      background-repeat: no-repeat;
      padding:  rem-calc(5 15 5 35);
      border:1px solid $primary-color;
      border-radius: 20px;
      display: block;
      background-position: 15px 8px;
      &:hover{
        background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2213%22%20height%3D%2216%22%20viewBox%3D%220%200%2013%2016%22%3E%0A%20%20%20%20%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%0A%20%20%20%20%20%20%20%20%3Cg%20fill%3D%22%23ffffff%22%20fill-rule%3D%22nonzero%22%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%3Cg%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3Cpath%20d%3D%22M8.206%202.74v1.045C10.33%204.518%2011.863%206.58%2011.863%209c0%203.033-2.406%205.5-5.363%205.5-2.957%200-5.362-2.467-5.362-5.5%200-2.421%201.533-4.482%203.656-5.215V2.74C2.126%203.505.163%206.022.163%209c0%203.584%202.843%206.5%206.337%206.5%203.494%200%206.338-2.916%206.338-6.5%200-2.978-1.963-5.495-4.632-6.26z%22%20transform%3D%22translate(-1130%20-45)%20translate(1130%2045)%22%2F%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3Cpath%20d%3D%22M6.013%200.5L6.987%200.5%206.987%209%206.013%209z%22%20transform%3D%22translate(-1130%20-45)%20translate(1130%2045)%22%2F%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%3C%2Fg%3E%0A%20%20%20%20%20%20%20%20%3C%2Fg%3E%0A%20%20%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A");
        background-color: $primary-color;
        color: #fff;
      }
    }

  }
  // navegacio top (administracio - logout)
  .nav-top{
    margin-top:rem-calc(32);
    margin-bottom:rem-calc(16);
    li{
      margin-right: 0;
      padding:rem-calc(5 0 5 10);

    }

  }
  // navegacio menu principal
  .nav-menu{
    font-weight:bold;
    margin-top:0;
    margin-bottom:rem-calc(60);
    li{
        &:hover, &.active{
        border:1px solid $primary-color;
        border-radius: 20px;
        padding:rem-calc(4 9);
        text-decoration:none;
        }
    }

  }
  // navegacio lateral usuaris
  .nav-user {
    position: absolute;
    top: -200px;
    background-color: #fff;
    margin-top: 0;
    padding-top: 148px;
    width: 100%;
    z-index: 99;
    &::before {
      background-color: #fff;
      content: '';
      display: block;
      height: 100%;
      width: 1002%;
      position: absolute;
      top: 0;
      left: -1000%;
    }
    ul{
      margin-left: 0;
      margin-right:rem-calc(20);
      li{
        text-align: left;
        margin-top:rem-calc(10);
        &::before {
          content:none;
        }
      }
    }
  }

  &.mobile{
    display:none;
    position: absolute;
    padding-top:rem-calc(20);
    width: 100%;
    left: 0;
    z-index: 999;
    height: 100vh;
    li {
      display:inline-table;
      padding:2px 5px;
      margin-right:0px;
      font-size: rem-calc(16);
      &.has-dropdown{
        >a::after {
          border-color: $secondary-color transparent transparent transparent;
          }
        .dropdown{
          display: none;
          position: relative;
          z-index: 99;
          margin:0;
          padding: 4px 0px;
          li{
            display: block;
            border: none;
            background-color:transparent;
            border-radius: 0;
            padding: rem-calc(5 0);
            opacity: 0.9;
          }
        }
        &.open{
          .dropdown{
            display:block;
          }
        }
      }
      a{
        color:#fff;
        font-size: rem-calc(16);
      }
      .user{
        background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2216%22%20viewBox%3D%220%200%2012%2016%22%3E%0A%20%20%20%20%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%0A%20%20%20%20%20%20%20%20%3Cg%20fill%3D%22%23fff%22%20fill-rule%3D%22nonzero%22%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%3Cg%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3Cpath%20d%3D%22M10.863%2010.74L8.587%209.26l.858-1.573c.192-.346.304-.758.305-1.197V4c0-1.933-1.567-3.5-3.5-3.5S2.75%202.067%202.75%204v2.49c0%20.439.113.851.312%201.21l-.007-.013.858%201.573-2.276%201.48C.95%2011.19.5%2011.957.5%2012.83v2.67H12v-2.665-.004c0-.873-.449-1.641-1.128-2.086l-.01-.006zM11%2014.5H1.5v-1.665-.002c0-.524.27-.985.677-1.252l.006-.003%203.052-1.984-1.302-2.385C3.818%207%203.751%206.754%203.75%206.49V4c0-1.381%201.12-2.5%202.5-2.5S8.75%202.62%208.75%204v2.49c0%20.263-.068.51-.187.726l.004-.008-1.302%202.386%203.052%201.984c.414.27.683.731.683%201.254V14.5z%22%20transform%3D%22translate(-983%20-45)%20translate(983%2045)%22%2F%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%3C%2Fg%3E%0A%20%20%20%20%20%20%20%20%3C%2Fg%3E%0A%20%20%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A');
        border:1px solid #fff;
        background-position: 15px 8px;
      }
      .logout{
        background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2213%22%20height%3D%2216%22%20viewBox%3D%220%200%2013%2016%22%3E%0A%20%20%20%20%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%0A%20%20%20%20%20%20%20%20%3Cg%20fill%3D%22%23fff%22%20fill-rule%3D%22nonzero%22%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%3Cg%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3Cpath%20d%3D%22M8.206%202.74v1.045C10.33%204.518%2011.863%206.58%2011.863%209c0%203.033-2.406%205.5-5.363%205.5-2.957%200-5.362-2.467-5.362-5.5%200-2.421%201.533-4.482%203.656-5.215V2.74C2.126%203.505.163%206.022.163%209c0%203.584%202.843%206.5%206.337%206.5%203.494%200%206.338-2.916%206.338-6.5%200-2.978-1.963-5.495-4.632-6.26z%22%20transform%3D%22translate(-1130%20-45)%20translate(1130%2045)%22%2F%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3Cpath%20d%3D%22M6.013%200.5L6.987%200.5%206.987%209%206.013%209z%22%20transform%3D%22translate(-1130%20-45)%20translate(1130%2045)%22%2F%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%3C%2Fg%3E%0A%20%20%20%20%20%20%20%20%3C%2Fg%3E%0A%20%20%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A");
        border:1px solid #fff;
        background-position: 15px 8px;
        width: 100px;
        margin:auto;
      }

    }
    // navegacio top (administracio - logout)
    .nav-top{
        text-align: center;
        margin-bottom:0;
        li{
          width:115px;
          .dropdown{
            margin-top:rem-calc(20);
            width:255%;
            li{
              width: auto;
              margin-bottom: rem-calc(5);
              span ,a {
                display: block;
                border:1px solid #fff;
                color:#fff;
                border-radius: rem-calc(20);
                padding: rem-calc(5 0);
              }
              a{
                background-color: #fff;
                color:$primary-color;
              }
            }
          }
          a{
            font-size:rem-calc(14);
          }
        }
    }

    // navegacio menu principal
    .nav-menu{
      font-weight:bold;
      margin-top:rem-calc(20);
      margin-bottom:rem-calc(40);
      li{
        display: block;
        text-align: center;
        padding:1rem;
        .dropdown{
          margin-top:rem-calc(20);
          width:72%;
          margin-left:auto;
          margin-right:auto;
          li{
            width: auto;
            margin-bottom: rem-calc(5);
            span ,a {
              display: block;
              border:1px solid #fff;
              color:#fff;
              border-radius: rem-calc(20);
              padding: rem-calc(5 0);
            }
            a{
              background-color: #fff;
              color:$primary-color;
              font-size:rem-calc(14);
            }
          }
        }
      }

    }
  }
  &.open{
    display:block;
    transition: 2s;
  }
}

// navegacio lateral usuaris
.nav-user {
  position: absolute;
  top: -200px;
  background-color: #fff;
  margin-top: 0;
  padding-top: 148px;
  width: 100%;
  z-index: 99;
  &::before {
    background-color: #fff;
    content: '';
    display: block;
    height: 100%;
    width: 1002%;
    position: absolute;
    top: 0;
    left: -1000%;
  }
  ul{
    margin-left: 0;
    margin-right:rem-calc(20);
    li{
      text-align: left;
      margin-top:rem-calc(10);
      &::before {
        content:none;
      }
    }
  }
}

/*
.main-nav {

  // nav mobile
  margin-bottom: $paragraph-margin-bottom;
  background: $primary-color;
  padding: rem-calc(27 23);
  transition: all 0.3s ease-out;

  h2 {
    @include visually-hidden;
  }

  ul {
    list-style: none;
    font-size: rem-calc(16);
    margin: 0;
    text-transform: uppercase;

    a {
      color: $white;
      display: block;
      padding: 0.5em 0;
    }
  }

  .js & {
    display: none;
    position: absolute;
    top: 69px;
    right: $column-gutter/2;
    z-index: 1;
    width: rem-calc(226);

    &.open {
      display: block;
    }
  }

  // nav medium
  @media #{$medium-up} {

    @include clearfix;
    display: block !important;
    background: $secondary-color;
    margin-bottom: rem-calc(44);
    padding: 0;
    position: static !important;
    width: auto !important;
    margin-top: 0 !important;

    .menu-wrapper {
      @include grid-row;
    }

    ul {
      @include grid-column;
      position: relative;
      font-size: rem-calc(16);
      float: none;

      li {
        display: inline-block;
        position: relative;

        > a {
          color: $black;
          padding: 0.75em;

          &:hover,
          &.is-hovered {
            text-decoration: none;
            background: $white;
            color: $black;
            &:after {
              border-top-color: $secondary-color !important;
            }
          }
        }

        .home &.inici {
          display: none;
        }

        &.active {
          > a {
            background: $primary-color;
            color: $white;
          }
        }

        &.has-submenu {
          > a {
            &:after {
              @include triangle(4px, $primary-color, top);
              display: inline-block;
              margin-left: 10px;
            }
          }
        }

        &.has-submenu:hover a + .submenu {
          left: 0;
        }
      }
    }

    .submenu {
      position: absolute;
      width: rem-calc(300);
      padding: 0;
      background: $secondary-color;
      overflow: hidden;
      z-index: 10000;
      display: block;
      margin: 0;
      text-transform: none;
      left: -999999em;
      top: 49px;
      text-align: left;
      font-size: rem-calc(16);

      li {
        float: none;
        display: block;
      }

      a {
        padding: 0.75em 1.5em;
        height: auto;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}*/
