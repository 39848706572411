.btn-dropdown{
    position: relative;
    text-align: right;
    ul{
        display: none;
        position: absolute;
        border:1px solid $secondary-color;
        z-index: 99;
        padding: 10px 0;
        margin-left: 0;
        right:0;
        background-color: #fff;
        width: 220px;
        top:30px;
        li{
            margin: 0;
            border: none;
            border-radius: 0;
            text-align: left;
            padding: 0;

            &::before{
                content:none;
            }
            a{
                font-size: rem-calc(14);
                display: block;
                line-height: rem-calc(20);
                padding: rem-calc(5 20 5 20);
                &:hover {
                	background:$tertiary-color;
                    color:#fff;
                }
            }
        }
        &.icon{
           /* right:rem-calc(-55);*/
            li{
                padding: 0;

                a{
                    display: block;
                    padding: rem-calc(5 20 5 20);

                }
            }
        }
        &.open{
            display:block;
        }
        &.filter{
            min-width: rem-calc(200);
            background-image: none;
            font-weight: normal;
            margin-top: .5rem;
            border: 1px solid #ebebeb;
            background-color: rgba(255,255,255,.95);
        }
    }
    /*&:hover{
        ul{
            display: block;
        }
    } */
}
