// webfonts declarations
// @font-face {
//   font-family: 'titillium_webthin';
//   src: url('/fonts/titilliumweb-extralight-webfont.eot');
//   src: url('/fonts/titilliumweb-extralight-webfont.eot?#iefix') format('embedded-opentype'), url('/fonts/titilliumweb-extralight-webfont.woff2') format('woff2'), url('/fonts/titilliumweb-extralight-webfont.woff') format('woff'), url('/fonts/titilliumweb-extralight-webfont.ttf') format('truetype');
//   font-weight: 300;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'titillium_webregular';
//   src: url('/fonts/titilliumweb-regular-webfont.eot');
//   src: url('/fonts/titilliumweb-regular-webfont.eot?#iefix') format('embedded-opentype'), url('/fonts/titilliumweb-regular-webfont.woff2') format('woff2'), url('/fonts/titilliumweb-regular-webfont.woff') format('woff'), url('/fonts/titilliumweb-regular-webfont.ttf') format('truetype');
//   font-weight: 400;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'titillium_webitalic';
//   src: url('/fonts/titilliumweb-italic-webfont.eot');
//   src: url('/fonts/titilliumweb-italic-webfont.eot?#iefix') format('embedded-opentype'), url('/fonts/titilliumweb-italic-webfont.woff2') format('woff2'), url('/fonts/titilliumweb-italic-webfont.woff') format('woff'), url('/fonts/titilliumweb-italic-webfont.ttf') format('truetype');
//   font-weight: 400;
//   font-style: italic;
// }

// @font-face {
//   font-family: 'titillium_websemibold';
//   src: url('/fonts/titilliumweb-semibold-webfont.eot');
//   src: url('/fonts/titilliumweb-semibold-webfont.eot?#iefix') format('embedded-opentype'), url('/fonts/titilliumweb-semibold-webfont.woff2') format('woff2'), url('/fonts/titilliumweb-semibold-webfont.woff') format('woff'), url('/fonts/titilliumweb-semibold-webfont.ttf') format('truetype');
//   font-weight: 600;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'titillium_websemibold_italic';
//   src: url('/fonts/titilliumweb-semibolditalic-webfont.eot');
//   src: url('/fonts/titilliumweb-semibolditalic-webfont.eot?#iefix') format('embedded-opentype'), url('/fonts/titilliumweb-semibolditalic-webfont.woff2') format('woff2'), url('/fonts/titilliumweb-semibolditalic-webfont.woff') format('woff'), url('/fonts/titilliumweb-semibolditalic-webfont.ttf') format('truetype');
//   font-weight: 600;
//   font-style: italic;
// }

// @font-face {
//   font-family: 'titillium_webbold';
//   src: url('/fonts/titilliumweb-bold-webfont.eot');
//   src: url('/fonts/titilliumweb-bold-webfont.eot?#iefix') format('embedded-opentype'), url('/fonts/titilliumweb-bold-webfont.woff2') format('woff2'), url('/fonts/titilliumweb-bold-webfont.woff') format('woff'), url('/fonts/titilliumweb-bold-webfont.ttf') format('truetype');
//   font-weight: 700;
//   font-style: normal;
// }

// mixins
// @mixin thin {
//   font-family: titillium_webthin, $font-family-sans-serif;
//   font-weight: 300;
//   font-style: normal;
// }

// @mixin regular {
//   font-family: titillium_webregular, $font-family-sans-serif;
//   font-weight: 400;
//   font-style: normal;
// }

// @mixin regular-italic {
//   font-family: titillium_webitalic, $font-family-sans-serif;
//   font-weight: 400;
//   font-style: italic;
// }

// @mixin semibold {
//   font-family: titillium_websemibold, $font-family-sans-serif;
//   font-weight: 600;
//   font-style: normal;
// }

// @mixin semibold-italic {
//   font-family: titillium_websemibold_italic, $body-font-family;
//   font-weight: 600;
//   font-style: italic;
// }

// @mixin bold {
//   font-family: titillium_webbold, $font-family-sans-serif;
//   font-weight: 700;
//   font-style: normal;
// }

@font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 400;
    src: local('Lato Italic'), local('Lato-Italic'), url(../../fonts/Lato-Italic.ttf) format('truetype'), url(../../fonts/Lato-Italic.woff2) format('woff2'), url(../../fonts/Lato-Italic.woff) format('woff');
  }
  
  @font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 700;
    src: local('Lato Bold Italic'), local('Lato-BoldItalic'), url(../../fonts/Lato-Bold-Italic.ttf) format('truetype'), url(../../fonts/Lato-Bold-Italic.woff2) format('woff2'), url(../../fonts/Lato-Bold-Italic.woff) format('woff');
  }
  
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 300;
    src: local('Lato Light'), local('Lato-Light'), url(../../fonts/Lato-Light.ttf) format('truetype'), url(../../fonts/Lato-Light.woff2) format('woff2'), url(../../fonts/Lato-Light.woff) format('woff');
  }
   
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: url(../../fonts/Lato-Regular.eot);
    src: local('Lato Regular'), local('Lato-Regular'), local('Lato'), url(../../fonts/Lato-Regular.ttf) format('truetype'), url(../../fonts/Lato-Regular.woff2) format('woff2'), url(../../fonts/Lato-Regular.woff) format('woff'), url(../../fonts/Lato-Regular.eot?#iefix) format('embedded-opentype'), url(../../fonts/Lato-Regular.svg#Lato) format('svg');
  }
  
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    src: local('Lato Bold'), local('Lato-Bold'), url(../../fonts/Lato-Bold.ttf) format('truetype'), url(../../fonts/Lato-Bold.woff2) format('woff2'), url(../../fonts/Lato-Bold.woff) format('woff');
  }
  
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    src: local('Lato Black'), local('Lato-Black'), url(../../fonts/Lato-Black.ttf) format('truetype'), url(../../fonts/Lato-Black.woff2) format('woff2'), url(../../fonts/Lato-Black.woff) format('woff');
  }
  
  