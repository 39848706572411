// @include typi-init;
html {
  font-size: 100%;
  line-height: 1.5;
}
body {
  background: $body-bg;
  color: $body-font-color;
  font-family: $body-font-family;
  font-weight: 400;
}
h1,
.h1 {
  font-weight: 900;
  font-size: rem-calc(22);
  line-height: 1.1;
  margin-bottom: $spacing * 0.5;
  @media #{$medium-up} {
    font-size: rem-calc(28);
  }
  &.tipus2 {
    font-weight: 200;
  }
  .top-img--area & {
    font-weight: 200;
    strong {
      display: block;
    }
  }
}
h2,
.h2 {
  font-size: rem-calc(22);
  margin-bottom: $spacing * 1.5;
  font-weight: 400;
  color: #000;
  line-height: 1.2;
  @media #{$medium-up} {
    font-size: rem-calc(22);
  }
  @media #{$large-up} {
    font-size: rem-calc(22);
  }
  .col-title & {
    @media screen and (min-width: $small-breakpoint) {
      margin-top: 0;
    }
  }
  .text-container & {
    @extend .h3;
  }
}
h3,
.h3 {
  font-size: rem-calc(22);
  color: #000;
  margin-top: 0;
  margin-bottom: $spacing ;
  font-weight: 600;
}
h4,
.t4 {
  font-size: rem-calc(16);
  font-weight: bold;
  line-height: 1.2;
}
p,
ol,
ul,
dl {
  // font-size: rem-calc(16);
  // line-height: 1.6;
  @media #{$medium-up} {
    font-size: rem-calc(16);
  }
  @media #{$xlarge-up} {
    font-size: rem-calc(16);
  }

  // classes per element contenidor
  .smallest & {
    font-size: rem-calc(12);
  }
  .smaller & {
    font-size: rem-calc(14);
  }
  .small & {
    font-size: rem-calc(16);
  }
  .normal & {
    font-size: rem-calc(18);
  }
  .big & {
    font-size: rem-calc(20);
  }
  .bigger & {
    font-size: rem-calc(21);
    line-height: 1.4;
    @media #{$medium-up} {
      font-size: rem-calc(22);
    }
  }
  .biggest & {
    font-size: rem-calc(22);
    line-height: 1.4;
    @media #{$medium-up} {
      font-size: rem-calc(24);
    }
  }

  // classes directes a l'element de text
  &.smallest {
    font-size: rem-calc(12);
  }
  &.smaller {
    font-size: rem-calc(14);
  }
  &.small {
    font-size: rem-calc(16);
  }
  &.normal {
    font-size: rem-calc(18);
  }
  &.big {
    font-size: rem-calc(20);
  }
  &.bigger {
    font-size: rem-calc(21);
    line-height: 1.4;
    @media #{$medium-up} {
      font-size: rem-calc(22);
    }
  }
  &.biggest {
    font-size: rem-calc(22);
    line-height: 1.4;
    @media #{$medium-up} {
      font-size: rem-calc(24);
    }
  }
}

ol,
dl,
dt,
dd,
figure,
img,
form,
hr,
table {
  margin: 0;
  padding: 0;
  // font-size: rem-calc(15);
}

.page-header{
  background-image: linear-gradient(to bottom, #fdfdfd, #f2f2f2);
  /*padding-bottom: rem-calc(20);*/
  &.tabs{
    padding-bottom: 0;
  }
  h1{
    margin-bottom:rem-calc(50);
    span{
    .info{
      display: inline-block;
      color:$secondary-color;
      font-weight: normal;
      position: relative;
      margin-left: rem-calc(30);
      strong{
        font-weight: 700;
      }
      &::before{
        border-left: 1px solid;
        content: '';
        left: -20px;
        height: 50px;
        position: absolute;
        top: -15px;
        padding-left: rem-calc(20);
       }
      }
    }
  }
  h2{
    margin-bottom: $spacing;
    font-weight: bold;
  }
  h3{
    font-weight: bold;
    margin-bottom: 0;
    font-size:rem-calc(16);
  }
  small{
    /*display: none;*/
  }
  /*.btn{
    margin:0;
  }  */
  .info{
      color:$secondary-color;
      &:before{
        content: '';
        border-left: 1px solid #979797;
        display: inline-block;
        margin-bottom: rem-calc(-10);
        height: rem-calc(45);;
        margin-left: rem-calc(20);
        padding-left: rem-calc(20);;
      }
  }

}
.main-content {
  ul,
  ol,
  figure {
    @include stack-small;
  }
}

.page-header{
    background-image: linear-gradient(to bottom, #fdfdfd, #f2f2f2);
    /*padding-bottom: rem-calc(20);*/
    &.tabs{
      padding-bottom: 0;
    }
    h1{
      margin-bottom:rem-calc(50);
      span{
      .info{
        display: inline-block;
        color:$secondary-color;
        font-weight: normal;
        position: relative;
        margin-left: rem-calc(30);
        strong{
          font-weight: 700;
        }
        &::before{
          border-left: 1px solid;
          content: '';
          left: -20px;
          height: 50px;
          position: absolute;
          top: -15px;
          padding-left: rem-calc(20);
         }
        }
      }
    }
    h2{
      margin-bottom: $spacing;
      font-weight: bold;
    }
    h3{
      font-weight: bold;
      margin-bottom: 0;
      font-size:rem-calc(16);
    }
    small{
      /*display: none;*/
    }
    /*.btn{
      margin:0;
    }  */
    .info{
        color:$secondary-color;
        &:before{
          content: '';
          border-left: 1px solid #979797;
          display: inline-block;
          margin-bottom: rem-calc(-10);
          height: rem-calc(45);;
          margin-left: rem-calc(20);
          padding-left: rem-calc(20);;
        }
    }

  }
ul {
  list-style: none;
  li {
    position: relative;
    break-inside: avoid;
    padding-left: $spacing * 1.5;
    /*&:before {
      content: "";
      display: block;
      height: 3px;
      width: $spacing;
      position: absolute;
      left: 0;
      top: 0.7em;
    }*/
    ul:not(.list-large) {
      color: #3f3f3f;
      margin-top: $spacing * 0.5;
      font-weight: normal;
      li {
        font-size: rem-calc(14);
        padding-left: $spacing * 0.75;
        &:before {
          height: $spacing * 0.25;
          width: $spacing * 0.25;
          top: 0.6em;
        }
      }
    }
  }
}

ol {
  margin-left: $spacing;
  li::marker {
    color: $tertiary-color;
    font-weight: bold;
  }
}

// llista simple, sense klanders
ul,
ol {
  &.simple-list {
    list-style: none;
    li {
      padding-left: 0;
      &:before {
        display: none;
      }
    }
  }
}

// llista amb separadors
.borders-list {
  @include undolist;
  li {
    margin-top: 0;
    padding: 0 0 $spacing * 0.5 0;
    &:first-child {
      padding-top: 0;
    }
    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      margin-top: $spacing * 0.5;
      background: linear-gradient(297deg, $grad-end, $grad-start);
    }
  }
  a {
    @include hover;
  }
}

// llista documents relacionats
.rel {
  @include undolist;
  font-size: rem-calc(14);
  li {
    background-color: #eeeeee;
    padding: $spacing * 0.75 $spacing;
  }
  a {
    // @include hover;
    font-weight: bold;
    &:after {
      content: "";
      display: inline-block;
      vertical-align: middle;
      font-weight: 300;
      background-color: #979797;
      width: 1px;
      height: $spacing;
      margin-left: $spacing * 0.75;
      margin-right: $spacing * 0.5;
    }
    /*&[href^="http://"]::after {
      content: '';
      background: url('img/external-link.svg') no-repeat;
      background-position: 100% 50%;
      width: 24px;
      width: calc(24px + 1rem);
      height: 24px;
      display: inline-block;
      font-size: 1rem;
      vertical-align: middle;
      height: 25px;
      //right: $spacing*.5;
      border-left: 1px solid #000;
      margin-left: $spacing*.75;
      padding: 17px 0;
    }
    &[href^="https://"]::after {
      content: '';
      background: url('img/external-link.svg') no-repeat;
      background-position: 100% 50%;
      width: 24px;
      width: calc(24px + 1rem);
      height: 24px;
      display: inline-block;
      font-size: 1rem;
      vertical-align: middle;
      height: 25px;
      //right: $spacing*.5;
      border-left: 1px solid #000;
      margin-left: $spacing*.75;
      padding: 17px 0;
    }
    &[href$=".doc"]::after {
      content: 'Word';
      display: inline-block;

      font-size: 1rem;
      vertical-align: middle;
      position: absolute;
      //right: rem-calc(43);
      top: -5px;
      border-left: 1px solid #000;
      margin-left: $spacing*.75;
      padding: 17px 0;
      @media #{$medium-up} {
        position: static;
      }
    }
     &[href$=".docx"]::after {
      content: 'Word';
      display: inline-block;

      font-size: 1rem;
      vertical-align: middle;
      position: absolute;
      //right: rem-calc(43);
      top: -5px;
      border-left: 1px solid #000;
      margin-left: $spacing*.75;
      padding: 17px 0;
      @media #{$medium-up} {
        position: static;
      }
    }
    &[href$=".pdf"]::after {
      content: 'PDF';
      padding: 17px 0;
      //right: $spacing*.5;
      vertical-align: middle;
      @media #{$medium-up} {
        position: static;
      }
    }*/
    &.onlyLink:after {
      display: none;
    }
  }
}

// llista gran
ul.list-large {
  @include undolist;
  font-size: rem-calc(20);
  font-weight: bold;
  border-top: 1px solid #979797;
  @media #{$medium-up} {
    font-size: rem-calc(22);
  }
  @media #{$large-up} {
    font-size: rem-calc(22);
  }
  li {
    padding: $spacing * 0.5 $spacing;
    border-bottom: 1px solid #979797;
    margin-top: 0;
    @media #{$medium-up} {
      padding: $spacing * 0.75 $spacing * 1.5;
    }
    @media #{$large-up} {
      padding: $spacing $spacing * 2;
    }
  }
}

.dl-inline {
  dt,
  dd {
    margin: 0;
    padding: 0;
  }
  dt {
    float: left;
    margin-right: 0.3em;
  }
}

figcaption {
  font-size: rem-calc(12);
}

::selection {
  // color: #fff;
  background-color: #d0d0d0;
  -webkit-text-fill-color: $body-font-color;
}
strong,
b {
  font-weight: 400;
}
// em,
// i {
// }

.upp {
  text-transform: uppercase;
}

// links underline
a {
  color: $link-color;
  /*.main-content &:not(.btn) {
    @supports (-webkit-background-clip: text) {
      background: linear-gradient(297deg, $grad-end, $grad-start);
      -webkit-background-clip: text;
    }
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
    text-decoration-color: $secondary-color;
    text-decoration-thickness: 1px;
    text-underline-offset: 3px;
    transition-property: text-decoration-color;
    transition-duration: 0.15s;
    transition-timing-function: ease-out;
    &:hover {
      text-decoration: none;
      text-decoration-color: transparent;
    }
  }*/
  &.external {
    main & {
      &:after {
        content: "";
        display: inline-block;
        vertical-align: middle;
        //@include sprite("external-link");
        font-size: 1rem;
        margin-left: $spacing * 0.25;
        opacity: 0.3;
        background-color: transparent;
      }
    }
  }
}

// link destacat
p.link-dest {
  font-size: rem-calc(22);
  font-weight: bold;
  line-height: 1.2;
  text-align: center;
  border: solid #979797;
  border-width: 1px 0;
  padding: $spacing $spacing * 0.5;
  display: inline-block;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  @media #{$medium-up} {
    font-size: rem-calc(24);
  }
  @media #{$medium-up} {
    font-size: rem-calc(26);
  }
}

.m{
    margin-top:0 !important;
    &--0{
        margin-top:0 !important;
    }
    &--1{
        margin-top:1rem !important;
    }
    &--2{
        margin-top:2rem !important;
    }
    &--3{
        margin-top:3rem !important;
    }
}

.m-0 {
    @extend .m;
}
.m-1 {
    @extend .m;
    @extend .m--1;
}

.m-2 {
    @extend .m;
    @extend .m--2;
}
.m-3 {
    @extend .m;
    @extend .m--3;
}

// focus
/*a,
button,
input,
select,
textarea,
[role="button"] {
  &:focus {
    outline: 3px solid $focus-color;
  }
}*/

.dest-1 {
  line-height: 1.2;
  background-color: #f2f2f2;
  padding: $spacing;
  border-radius: 8px;
  > dl {
    font-size: rem-calc(16);
  }
  dt:not(:first-child) {
    margin-top: $spacing;
  }
  dd {
    margin-top: $spacing * 0.25;
  }
  a[href*="mailto"] {
    word-wrap: break-word;
  }
}
.dest-2 {
  @include stack;
  text-align: center;
  border: solid #979797;
  border-width: 1px 0;
  padding: $spacing $spacing * 0.5;
  display: inline-block;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-top: $spacing * 2 !important;
  margin-bottom: $spacing * 2;
  h2 {
    color: $primary-color;
  }
  p:not(.btn) {
    font-size: rem-calc(20);
  }
}
.dest-3 {
  padding: $spacing;
  text-align: center;
  border-radius: 8px;
  background-image: linear-gradient(291deg, $grad-end, $grad-start);
  font-weight: bold;
  color: #fff;
  a {
    text-decoration: none !important;
    color: #fff !important;
    background: transparent !important;
    -webkit-text-fill-color: inherit !important;
    &:hover {
      text-decoration: underline !important;
    }
  }
}
.dest-4 {
  border: solid #979797;
  border-width: 1px 0;
  padding: $spacing * 0.5 0;
  @media #{$large-up} {
    font-size: rem-calc(18);
  }
  a {
    @include hover;
    color: $primary-color;
    -webkit-text-fill-color: $primary-color !important;
    &:hover {
      text-decoration-color: $primary-color !important;
    }
  }
}
p.dest,
.dest p {
  @extend .dest-1;
}

hr {
  border: 0;
  border-top: 1px solid #d9d9d9;
  margin: $spacing * 2 0 !important;
}
/*
table {
  width: 100%;
  font-size: rem-calc(16);
  font-weight: normal;
  th,
  td {
    vertical-align: baseline;
  }
  thead {
    th {
      color: $body-font-color;
      font-weight: bold;
      text-align: left;
      padding: 0 $spacing * 0.5 $spacing * 0.5;
      border-bottom: 1px solid $secondary-color;
      line-height: 1.2;
    }
  }
  tbody {
    td,
    th {
      padding: $spacing * 0.75 $spacing * 0.5;
      text-align: left;
      border-bottom: 1px solid #d9d9d9;
    }
    th {
      font-weight: bold;
    }
  }
}
.table-container {
  width: 100%;
  overflow-y: auto;
  _overflow: auto;
}
.table-container::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 14px;
  height: 14px;
}
.table-container::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 3px solid #fff;
  background-color: rgba(0, 0, 0, 0.3);
}
*/
.inline-list {
  @include undolist;
  font-size: 0;
  margin-left: -$spacing;
  margin-right: -$spacing;
  text-align: center;
  li {
    font-size: 1rem;
    display: inline-block;
    vertical-align: middle;
    margin: $spacing $spacing 0;
  }
}

// tipus de paràgrafs
p.lead {
  font-weight: bold;
}
.intro {
  strong {
    font-weight: bold;
    position: relative;
    display: inline-block;
    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 3px;
      margin-top: 1px;
      background-image: linear-gradient(273deg, $grad-end, $grad-start);
      @media #{$medium-up} {
        height: 4px;
        margin-top: 2px;
      }
      @media #{$large-up} {
        height: 6px;
        margin-top: 3px;
      }
    }
  }
}
.results {
  font-size: 1rem;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 1px;
  strong {
    color: $tertiary-color;
    font-weight: inherit;
    font-size: rem-calc(20);
    @media #{$medium-up} {
      font-size: rem-calc(24);
    }
    @media #{$large-up} {
      font-size: rem-calc(26);
    }
    @media #{$xlarge-up} {
      font-size: rem-calc(30);
    }
  }
}

dt {
  font-weight: bold;
}


.info {
  a {
    display: block;
  }
}
.alert{
  h2{
    font-weight: 900;
    /*border-bottom: 1px solid #000;
    padding-bottom:rem-calc(20);
    */
    padding:0;
    margin:1rem 0;
  }
}
