.label {
	display: inline-block;
	font-family: Lato,"Helvetica Neue",Helvetica,Roboto,Arial,sans-serif;
	font-weight: 400;
	line-height: 1;
	margin-bottom: auto;
	position: relative;
	text-decoration: none;
	white-space: nowrap;
	font-size: rem-calc(12);
	background-color: $primary-color;
	color: #fff;
	border-radius: rem-calc(16);
	min-width: 150px;
	text-align: left;
	padding:6px 0px 6px 34px;
	&:hover, &:active{
		color: #fff;
	
	}
	&.success{
		background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2214%22%20height%3D%2212%22%20viewBox%3D%220%200%2014%2012%22%3E%0A%20%20%20%20%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%0A%20%20%20%20%20%20%20%20%3Cg%20fill%3D%22%23FFF%22%20fill-rule%3D%22nonzero%22%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%3Cg%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3Cg%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3Cpath%20d%3D%22M0%206.729L4.742%2011.346%2014%202.331%2011.601%200%204.731%206.674%202.399%204.398z%22%20transform%3D%22translate(-562%20-538)%20translate(562%20538)%22%2F%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3C%2Fg%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%3C%2Fg%3E%0A%20%20%20%20%20%20%20%20%3C%2Fg%3E%0A%20%20%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A");
		background-repeat: no-repeat;
		background-position: 10px 6px;	  
		background-color: #82c347;
		background-size: 15px 13px;		
	}	
	&.pendent{
		background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2214%22%20height%3D%2214%22%20viewBox%3D%220%200%2014%2014%22%3E%0A%20%20%20%20%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%0A%20%20%20%20%20%20%20%20%3Cg%20fill%3D%22%23FFF%22%20fill-rule%3D%22nonzero%22%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%3Cg%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3Cg%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3Cg%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3Cpath%20d%3D%22M7.424%202.612L6.549%202.612%206.549%207.482%209.71%208.985%2010.086%208.194%207.424%206.929z%22%20transform%3D%22translate(-561%20-638)%20translate(561%20638)%22%2F%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3Cpath%20d%3D%22M7%20.438C3.376.438.437%203.376.437%207S3.377%2013.563%207%2013.563c3.624%200%206.563-2.939%206.563-6.563C13.563%203.376%2010.623.437%207%20.437zm0%2012.25c-3.141%200-5.688-2.547-5.688-5.688C1.313%203.859%203.86%201.312%207%201.312c3.141%200%205.688%202.547%205.688%205.688%200%203.141-2.547%205.688-5.688%205.688z%22%20transform%3D%22translate(-561%20-638)%20translate(561%20638)%22%2F%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3C%2Fg%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3C%2Fg%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%3C%2Fg%3E%0A%20%20%20%20%20%20%20%20%3C%2Fg%3E%0A%20%20%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A");
		background-repeat: no-repeat;
		background-position: 10px 6px;	  
		background-color: #9b9b9b;
		background-size: 15px 13px;		
	}
	&.rebutjat, &.eliminat, &.saltat{ 
		background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2221%22%20height%3D%2221%22%20viewBox%3D%220%200%2021%2021%22%3E%0A%20%20%20%20%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%0A%20%20%20%20%20%20%20%20%3Cg%20fill%3D%22%23FFF%22%20fill-rule%3D%22nonzero%22%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%3Cg%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3Cpath%20d%3D%22M21%201.387L19.613%200%2010.5%209.113%201.387%200%200%201.387%209.113%2010.5%200%2019.613%201.387%2021%2010.5%2011.887%2019.613%2021%2021%2019.613%2011.887%2010.5z%22%20transform%3D%22translate(-150%20-82)%20translate(150%2082)%22%2F%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%3C%2Fg%3E%0A%20%20%20%20%20%20%20%20%3C%2Fg%3E%0A%20%20%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A");
		background-repeat: no-repeat;
		background-position: 10px 6px;	  
		background-color: #d84848;
		background-size: 15px 13px;	 	
	}
	&.incidencia{
		background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2215%22%20height%3D%2213%22%20viewBox%3D%220%200%2015%2013%22%3E%0A%20%20%20%20%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%0A%20%20%20%20%20%20%20%20%3Cg%20fill%3D%22%23FFF%22%20fill-rule%3D%22nonzero%22%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%3Cg%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3Cg%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3Cg%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3Cpath%20d%3D%22M5.817.937L5.82.934C6.175.354%206.813%200%207.5%200c.688%200%201.325.354%201.683.937l5.555%209.153c.349.597.35%201.332.005%201.93-.346.6-.988.972-1.693.98H1.943c-.698-.008-1.34-.38-1.686-.98-.346-.598-.343-1.333.011-1.939L5.818.937zm-4.42%209.8c-.116.199-.117.444-.001.643.115.2.329.324.554.327h11.092c.232-.003.446-.127.562-.327.115-.2.114-.444.003-.634L8.06%201.605c-.119-.194-.331-.312-.56-.312-.229%200-.44.118-.56.31l-5.543%209.134zm5.436-6.13c0-.363.299-.658.667-.658.368%200%20.667.295.667.659V7.24c0%20.363-.299.658-.667.658-.368%200-.667-.295-.667-.658V4.608zm.196%205.567c-.26-.257-.26-.674%200-.93.26-.258.682-.258.942%200%20.26.256.26.673%200%20.93s-.682.257-.942%200z%22%20transform%3D%22translate(-562%20-688)%20translate(562%20688)%22%2F%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3C%2Fg%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3C%2Fg%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%3C%2Fg%3E%0A%20%20%20%20%20%20%20%20%3C%2Fg%3E%0A%20%20%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A");
		background-repeat: no-repeat;
		background-position: 10px 5px;	  
		background-color: #e1752e;
		background-size: 15px 13px;		 
	}
}		
