.alert {
	display: block;
	font-weight: 400;
	line-height: 1;
	display: block;
	width: 100%;
	/*margin-bottom: 20px;*/
	position: relative;
	text-decoration: none;
	white-space: nowrap;
	font-size: rem-calc(12);
	color: #000;
    border-radius: rem-calc(10);
	ul{
		width: 90%;
		margin-top: 2px;
		padding: 20px 20px 25px 20px;
	}
	/*.alert-heading{
		margin-bottom: rem-calc(10);
	}*/
	&.alert-success{
		border-color:  #dff0d8;
		background-color: #dff0d8;
		color: #468847;
		.btn{
			background-color: #468847;
			border-color: #468847;
		}
		.alert-heading{
			color: #468847;
		}
	}
	&.alert-error{
		border-color:  #f2dede;
		background-color: #f2dede;
		color: #b94a48;
		.btn{
			background-color: #b94a48;
			border-color: #b94a48;
		}
		.alert-heading{
			color: #b94a48;
		}
	}
	&.alert-warning{
		border-color:  #fcf8e3;
		background-color: #fcf8e3;
		color:#c09853;
		.btn{
			background-color: #c09853;
			border-color: #c09853;
		}
		.alert-heading{
			color: #c09853;
		}
	}
	&.alert-notice{
		border-color: #f2f2f2;
		background-color: #f2f2f2;
		.btn{
			background-color: #000;
			border-color: #000;
		}
		.alert-heading{
			color: #000;
		}
	}
	&.alert-info{
		border-color: #d9edf7;
		background-color: #d9edf7;
		color:#3a87ad;
		.btn{
			background-color: #3a87ad;
			border-color: #3a87ad;
		}
		.alert-heading{
			color: #3a87ad;
		}
	}
}

#alert-box{
	padding: rem-calc(10);
	border-radius: rem-calc(16);
    margin-bottom: 2rem;

}

#alert-box-noscript{
	margin-top:$spacing;
	padding: rem-calc(10 20);
	border-radius: rem-calc(16);
    p{
        margin:0
    }
}

.houdiniForm .alert-box.alert {
	label.error {
		color: #fff;
	}
}
