.main-footer {
  background: $primary-color;
  margin-top: 3rem;
  padding: rem-calc(27 0);

  p,
  ul {
    font-size: rem-calc(14);
    margin-bottom:0;

    @media #{$small-up} {
      font-size: rem-calc(15);
    }

    @media #{$medium-up} {
      font-size: rem-calc(16);
    }

    @media #{$large-up} {
      font-size: rem-calc(16);
    }
  }


  .footer-text {
    @include grid-column($columns: 12);
    color:#fff;
  }
}
